<template>

<div class="title">

	{{ input.label }}

</div>

</template>

<script>

export default {

	props: ['input']

}

</script>

<style scoped>

.title {
	margin-bottom: 20px;
	color: #1277D4;
	font-size: 24px;
	line-height: 30px;
	font-weight: 300;
	padding: 0px 15px;
}

</style>
